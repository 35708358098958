import {useEffect, useState} from 'react';

export const useIsPastScroll = (threshold=0): boolean => {
  const [hasScrolled, setHasScrolled] = useState<boolean>(false);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handler = () => {
        if (!hasScrolled && window.scrollY > threshold) {
          setHasScrolled(true);
        }
        if (hasScrolled && window.scrollY <= threshold + 1) {
          setHasScrolled(false);
        }
      };
      window.addEventListener('scroll', handler);
      handler();
    }
  }, []);
  return hasScrolled;
};