import React from 'react';
import s from './made-with-love.module.scss';
import { c } from '../../utils';
import {ComponentProps} from '../../types';

const Heart = () => (
  <svg x="0px" y="0px" viewBox="0 0 15 15">
    <path fill="#E4C05C"
          d="M13.91,6.75c-1.17,2.25-4.3,5.31-6.07,6.94c-0.1903,0.1718-0.4797,0.1718-0.67,0C5.39,12.06,2.26,9,1.09,6.75  C-1.48,1.8,5-1.5,7.5,3.45C10-1.5,16.48,1.8,13.91,6.75z"/>
  </svg>
);

const MadeWithLove = ({ className, ...props }: ComponentProps) => {
  return (
    <div className={c(s.madeWithLove, className)} {...props}>
      <div>made with <Heart/> by</div>
      <div>BROOKE & VIVIANO</div>
    </div>
  );
};

export default MadeWithLove;