/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import '../index.scss';
import {graphql, Link, useStaticQuery} from 'gatsby';
import MadeWithLove from '../components/MadeWithLove/MadeWithLove';
import {useIsPastScroll} from '../hooks/useIsPastScroll';
import {c, handleize} from '../utils';
import Arrow from '../components/Arrow';
import { Helmet } from 'react-helmet';

export const query = graphql`
  query {
    stepsImage: file(relativePath: { eq: "shoot_steps.jpg" }) {
      childImageSharp {         
        gatsbyImageData(width: 600, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
  }`;

const faqs = [
  {
    q: 'Is there parking at the event?',
    a: <p>You can find parking info for the Park Plaza <a href='https://www.bostonparkplaza.com/hotel/faqs/' target='_blank' rel="noreferrer">here</a><br/>
      and parking info for the church <a href='https://holycrossboston.com/visit/' target='_blank' rel="noreferrer">here</a>.<br/>
      We recommend parking at or near the Park Plaza and taking a cab or Uber to the church,
      since both locations are only a 5-7 minute ride from each other.
    </p>
  },
  {
    q: 'What Should I Wear?',
    a: 'Attire is Black Tie. This translates to full length gowns for women, and tuxedos for men.' +
      ' The ceremony, cocktail hour & reception will all be held indoors.'
  },
  {
    q: 'What Time Should I Arrive?',
    a: 'We recommend that you arrive an hour before the start of the ceremony,' +
      ' to make sure everyone is on time and we can get the ceremony started as scheduled.'
  },
  {
    q: 'Can I Bring a Guest?',
    a: 'In order to keep our guest list on track we are only able to accommodate' +
      ' those guests formally invited on your wedding invitation.' +
      ' Please feel free to email us with any questions!'
  },
  {
    q: 'Are Kids Invited to the Wedding?',
    a: 'While we love your little ones, our wedding is going to be an adults-only' +
      ' event so that everyone can relax and enjoy the evening. We appreciate you' +
      ' making arrangements ahead of time and leaving the kids at home so you can celebrate with us.'
  }
];

const Faqs = ({ data }: any) => {
  const scrolled = useIsPastScroll();

  return (
    <main className='faqs'>
      <Helmet>
        <meta charSet="utf-8" />
        <title>FAQs | The Cantú's</title>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": [
              ${faqs
      .filter(({ a }) => typeof a === 'string')
      .map(({ q, a }) => `{
                "@type": "Question",
                "name": "${q}",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "${a}"
                }
              }`).join(',')}]
            }
          `}
        </script>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org/",
              "@type": "BreadcrumbList",
              "itemListElement": [{
                "@type": "ListItem",
                "position": 1,
                "name": "Home",
                "item": "https://thecantus.com"
              },{
                "@type": "ListItem",
                "position": 2,
                "name": "FAQs",
                "item": "https://thecantus.com/faqs"
              }]
            }
         `}
        </script>
      </Helmet>

      <Link to={'/#more-info'} className={c('back-arrow', scrolled && 'shadow')}>
        <Arrow /> BACK
      </Link>

      <h1>FAQs</h1>

      {faqs.map(({ q, a }, i) => (<React.Fragment key={i}>
        <hr/>
        <div id={handleize(q)}>
          <h2>{q}</h2>
          <p>{a}</p>
        </div>
      </React.Fragment>))}


      <MadeWithLove style={{ marginTop: '20vh'}}/>

    </main>
  );
};
export default Faqs;
